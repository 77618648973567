import React from "react";

const About = () => {
  return (
    <div name="about" className="w-full h-screen bg-black text-gray-300 sm:pt-0 pt-12 min-h-screen">
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="pb-8 pl-4">
            <p className="text-4xl font-bold inline border-b-4 border-yellow-400">
              About
            </p>
          </div>
          <div></div>
        </div>
        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
          {/* <div className="sm:text-right text-4xl font-bold ">
            <p>Hi. I'm Fredrik, nice to meet you. Please take a look around.</p>
          </div> */}
          <div>
            <p className="text-lg">
            I am a recently graduated developer with a passion for web development, from building new websites to improving existing ones.
            My interest in this field began three years ago, and two years ago, I started my studies in Web Development .NET. 
            Since then, I have continued to grow my skills both through formal education and independent learning in my free time. 
            My goal is to excel as a Fullstack or Frontend Developer, whether working on web projects or system development. 
            I am currently seeking my first opportunity to start my career as a developer and bring my enthusiasm and skills to a professional team.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
